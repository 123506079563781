import React, { Fragment } from 'react'

import {
  inspired,
  container,
  contentView,
  columns, 
  column,
  visionBox
} from './styles/EstudeAqui.styles'

import {
  btn2,
} from '../components/styles/InternalPages.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired}>
      <div css={container}>
        <div css={visionBox}>
          <div css={columns}>
            <div css={column}>
              <div css={contentView}>
                <h2>
                  POCKET<br /> SEGUNDA GUERRA
                </h2>
                <p style={{ marginBottom: 36 }}>O conflito de proporções gigantescas que alterou a história do mundo, transformou o tabuleiro político, chocou a civilização com a sua brutalidade e seus horrores, derrotou os horrores do holocausto, evidenciou a aposta do ser humano nos valores democráticos frente o totalitarismo e ceifou a vida de milhões de pessoas. A Segunda Guerra Mundial trouxe novos protagonistas para o centro do jogo político - Estados Unidos e, à época, URSS -, novos conflitos ideológicos - capitalismo e o socialismo - e novos alertas para a humanidade: a conquista da democracia e da fraternidade universal estarão sempre correndo riscos. Evento histórico fundamental, a Segunda Guerra Mundial é o tema desta Olimpíada Pocket, que abrange o conflito em todos os seus aspectos: disputas políticas, impactos econômicos e sociais, conflitos ideológicos, militaria, etapas históricas, tecnologia, fatos e dados que ajudem a compreender como a humanidade, após tantas conquistas, produziu um conflito tão assombroso.</p>
                <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn2} style={{ margin: '0 auto' }}>ACESSE A PROVA</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default InspiredSite
